import { template as template_321cc2b4c0dd4826903da5d141e0fdcf } from "@ember/template-compiler";
const FKControlMenuContainer = template_321cc2b4c0dd4826903da5d141e0fdcf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
