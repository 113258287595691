import { template as template_77e07671f9f745f4b380afbe1a492779 } from "@ember/template-compiler";
const SidebarSectionMessage = template_77e07671f9f745f4b380afbe1a492779(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
